import React, { useCallback } from 'react';
import type { MouseEvent } from 'react';

import Avatar from '@pinvest/sirius-avatar/dist/avatar';
import ChevronDown16Icon from '@pinvest/sirius-icons/dist/ChevronDown16';
import Link from 'next/link';

import { accountLinks } from '@/components/shared/NavigationBar/config';
import type { NavbarLinkType } from '@/components/shared/NavigationBar/types';
import Text from '@/components/shared/Text';

import styles from './styles.module.css';
import type { AccountProps } from './types';

export default function Account({
  name,
  onLinkClick,
  onLogOut,
  photoURL,
  renderAvatarImage
}: AccountProps) {
  const createLinkClickHandler = useCallback((link: NavbarLinkType) => (event: MouseEvent<HTMLAnchorElement>) => {
    if (typeof onLinkClick === 'function') onLinkClick(link, event);
  }, [onLinkClick]);

  return (
    <section className={styles['top-navigation__nav-item']}>
      <button className={styles['top-navigation__account-btn']}>
        <Avatar
          name={name}
          imageSrc={photoURL}
          renderImage={renderAvatarImage}
          size='compact'
          variant='circular'
        />
        <Text component='p' typography='body1' margin='0 5px'>{name}</Text>
        <ChevronDown16Icon />
      </button>
      <div className={styles['top-navigation__menu__content-container']}>
        <div className={styles['top-navigation__menu__content']}>
          <nav className={styles['top-navigation__sub-menu']}>
            <ul>
              {accountLinks.map(link => {
                const newLink = {
                  ...link,
                  href: `/${link.href}`
                };
                return (
                  <li key={newLink.href}
                  >
                    <Link
                      href={newLink.href}
                      legacyBehavior
                      passHref
                    >
                      <a
                        className={styles['top-navigation__sub-menu__link']}
                        onClick={createLinkClickHandler(newLink)}
                        data-testid={`account-link-${newLink.name}`}
                      >
                        {newLink.label}
                      </a>
                    </Link>
                  </li>
                );
              })}
              <li>
                <button
                  className={styles['top-navigation__sub-menu__button']}
                  onClick={onLogOut}
                >
                  Keluar
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </section>
  );
}

