import { forwardRef } from 'react';

import clsx from 'clsx';

import styles from './styles.module.css';
import type { TextHTMLElement, TextProps } from './types';

const Text = forwardRef<TextHTMLElement, TextProps>(({
  children,
  color = 'default',
  component: Component = 'span',
  margin,
  textAlign,
  typography = 'body1'
}, ref) => {
  return (
    <Component
      ref={ref}
      className={clsx(styles.text, styles[`text_${typography}`])}
      style={{
        color: color === 'inherit' ? 'inherit' : `var(--color-text-${color})`,
        margin,
        textAlign
      }}
    >
      {children}
    </Component>
  );
});

export default Text;
