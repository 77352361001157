import type { FC } from 'react';

import Footer from '@/components/shared/Footer';

import styles from './styles.module.css';
import type { AppTemplateProps } from './types';


const AppTemplate: FC<AppTemplateProps> = ({
  children,
  showFooter = true,
  color
}) => {
  return (
    <div
      className={styles.layout}
      style={{
        backgroundColor: !color ? 'inherit' : `var(--color-background-${color})`
      }}
    >
      <main className={styles.layout__main}>
        {children}
      </main>
      {
        showFooter && (
          <Footer />
        )
      }
    </div>
  );
};

export default AppTemplate;
