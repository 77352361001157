const internalUrls = {
  home: '/',
  login: '/login',
  register: '/register',
  privacyPolicy: '/kebijakan-privasi',
  dasboard: '/dashboard',
  profile: '/profile',
  completeProfile: '/complete-profile',
  editProfile: '/edit-profile',
  blSubmission: '/pengajuan-bridging-loan',
  blConstructionSubmission: '/pengajuan-bridging-loan?type=bl_financing_type.construction',
  blNonConstructionSubmission: '/pengajuan-bridging-loan?type=bl_financing_type.non_construction',
  inProgressLoan: '/pengajuan-diproses',
  billingLoan: '/tagihan',
  historyLoan: '/riwayat',
  payment: '/pembayaran'
};

export default internalUrls;
