import React from 'react';

import Image from 'next/image';

import { pinfinanceLogoUrl } from '@/constants/images';

import type { PinfinanceLogoProps } from './type';

export default function PinfinanceLogo({ height, width }: PinfinanceLogoProps) {
  return (
    <span>
      <Image src={pinfinanceLogoUrl} data-testid="img-pinfinanceLogo" alt="pinfinance-logo" width={width ?? 133} height={height ?? 41} />
    </span>
  );
}