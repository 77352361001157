export enum PinfinanceDiscoveryOpenPageName {
  HOMEPAGE = 'homepage',
  ACD_HOMEPAGE = 'acd_homepage',
  BRIDGING_LOAN_HOMEPAGE = 'bridging_loan_homepage',
  ACD_B2B_HOMEPAGE = 'acd_b2b_homepage'
}

export enum PinfinanceDiscoveryAttr {
  HOMEPAGE_MENU = 'homepage_menu',
  ACD_MENU = 'acd_menu',
  BRIDGING_LOAN_MENU = 'bridging_loan_menu',
  ACD_LEARN_MORE = 'acd_learn_more',
  BRIDGING_LOAN_LEARN_MORE = 'bridging_loan_learn_more',
  DIRECT_LINK = 'direct_link',
  SIGN_IN_FORM = 'sign_in_form',
  SIGN_UP_FORM = 'sign_up_form'
}

export enum PinfinanceDiscoveryButtonName {
  ACD_SUBMISSION = 'acd_submission',
  ACD_B2B_SUBMISSION = 'acd_b2b_submission',
  CONTACT_US = 'contact_us'
}

export enum PinfinanceDiscoveryButtonNameAttr {
  SERVICE_SELECTION = 'service_selection',
  NAVBAR = 'navbar',
  HERO_BANNER = 'hero_banner',
  INFO_BANNER = 'info_banner',
  SIMULATION_SECTION = 'simulation_section',
  BOTTOM_BANNER = 'bottom_banner',
  HELP_BANNER = 'help_banner',
  PERSONAL_DATA_FORM = 'personal_data_form',
  OCCUPATION_DATA_FORM = 'occupation_data_form',
  BANK_ACCOUNT_FORM = 'bank_account_form',
  INDIVIDUAL_DOCUMENT_UPLOAD = 'individual_document_upload',
  WA_FLOATING = 'wa_floating',
  NAV_BAR_ACD_B2B_APPLY = 'nav_bar_acd_b2b_apply',
  HERO_BANNER_ACD_B2B_APPLY = 'hero_banner_acd_b2b_apply',
  ACD_B2B_LOAN_PRODUCT_SELECTION = 'acd_b2b_loan_product_selection',
  SIMULATION_ACD_B2B_APPLY = 'simulation_acd_b2b_apply',
  BOTTOM_BANNER_ACD_B2B_APPLY = 'bottom_banner_acd_b2b_app',
  PROMO_BANNER_ACD_B2B_APPLY = 'promo_banner_acd_b2b_apply'
}

export type PinfinanceDiscoveryOpenPage = {
  page_name: PinfinanceDiscoveryOpenPageName;
  attribute: PinfinanceDiscoveryAttr;
};

export type PinfinanceDiscoveryClickButtonParam = {
  page_name: PinfinanceDiscoveryOpenPageName;
  button_name: PinfinanceDiscoveryButtonName;
  attribute: PinfinanceDiscoveryButtonNameAttr;
};
