import type { FC } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';

import Avatar from '@pinvest/sirius-avatar/dist/avatar';
import { IconButton } from '@pinvest/sirius-button';
import Divider from '@pinvest/sirius-divider/dist/divider';
import { Close24Icon, Menu20Icon } from '@pinvest/sirius-icons';
import cx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import PinfinanceLogo from '@/components/shared/PinfinanceLogo';
import Text from '@/components/shared/Text';
import { CustomerType, customerTypeMapName } from '@/constants/customer';
import internalUrls from '@/constants/internalUrls';
import { useOAuth } from '@/contexts/oauth';
import useClickHandler from '@/hooks/useElementClick';
import cookie from '@/services/cookies';
import { PinfinanceDiscoveryAttr, PinfinanceDiscoveryOpenPageName } from '@/services/eventTracking/events/pinfinanceDiscovery/types';

import Account from './components/Account';
import { accountLinks } from './config';
import { navigationLinks } from './config';
import styles from './styles.module.css';
import type { NavbarLinkType, NavigationBarProps } from './types';
import { splitName } from './utils';

const createNavlinkHandler = (navLinkName: string) => {
  return () => {
    if (navLinkName === 'pinfinance-home') {
      cookie.add({
        name: PinfinanceDiscoveryOpenPageName.HOMEPAGE,
        value: JSON.stringify({ attribute: PinfinanceDiscoveryAttr.HOMEPAGE_MENU })
      });
    }

    if (navLinkName === 'komisi-instan') {
      cookie.add({
        name: PinfinanceDiscoveryOpenPageName.ACD_HOMEPAGE,
        value: JSON.stringify({ attribute: PinfinanceDiscoveryAttr.ACD_MENU })
      });
    }

    if (navLinkName === 'bridging-loan') {
      cookie.add({
        name: PinfinanceDiscoveryOpenPageName.BRIDGING_LOAN_HOMEPAGE,
        value: JSON.stringify({ attribute: PinfinanceDiscoveryAttr.BRIDGING_LOAN_MENU })
      });
    }
  };
};

const NavigationBar: FC<NavigationBarProps> = ({
  extraMainNavigationItems,
  extraMainNavigationItemsOverlay,
  extraNavigationItems,
  extraNavigationItemsOverlay,
  isShowMenu = true
}) => {
  const [isShowOverlayBurgerMenu, setIsShowOverlayBurgerMenu] = useState(false);
  const [isShowOverlayProfile, setIsShowOverlayProfileMenu] = useState(false);

  const router = useRouter();
  const { user, logout } = useOAuth();

  const handleMenuToogle = useCallback(() => {
    setIsShowOverlayProfileMenu(false);
    setIsShowOverlayBurgerMenu(prevState => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setIsShowOverlayProfileMenu(false);
    setIsShowOverlayBurgerMenu(prevState => !prevState);
  }, []);

  const overlayRef = useClickHandler(() => handleClose());

  const handleProfileToogle = useCallback(() => {
    setIsShowOverlayBurgerMenu(false);
    setIsShowOverlayProfileMenu(prevState => !prevState);
  }, []);

  const navLinks = useMemo(() => {
    if (!isShowMenu) {
      return null;
    }

    return navigationLinks.map((navLink: NavbarLinkType) => {
      return <Link key={navLink.label} href={navLink.href} >
        <a
          aria-current={router.pathname == navLink.href ? 'page' : undefined}
          className={cx(styles.navigation__item, styles['navigation__item-border'])}
          key={navLink.label}
          onClick={createNavlinkHandler(navLink.name)}
          data-testid='lnk-navigation-item'
        >
          <Text typography='body2' color='default'>{navLink.label}</Text>
        </a>
      </Link>;
    });

  }, [isShowMenu, router.pathname]);

  const profileLinks = useMemo(() => {
    return accountLinks.map((link: NavbarLinkType) => {
      return <Link key={link.label} href={link.href}>
        <a
          aria-current={router.pathname == link.href ? 'page' : undefined}
          className={styles.navigation__item}
          key={link.label}
        >
          <Text typography='body2' color='default'>{link.label}</Text>
        </a>
      </Link >;
    });
  }, [router.pathname]);

  const customerTypeName = useMemo(() => {
    if (user?.type == CustomerType.BUSINESS) {
      return customerTypeMapName.business;
    }

    return customerTypeMapName.personal;
  }, [user]);

  const handleLogout = useCallback(() => {
    router.push(internalUrls.home);
    logout();
  }, [logout, router]);

  return (
    <header className={styles.header}>
      <div className={styles.header__main__wrapper}>
        <div className={styles.header__wrapper__left}>
          {
            (isShowMenu || extraMainNavigationItems != null) && (
              <div className={styles.burger__menu__button}>
                <IconButton icon={<Menu20Icon />} testId='btn-navbar-burger-menu' onClick={handleMenuToogle} />
              </div>
            )
          }
          <Link href={internalUrls.home}>
            <a className={styles['logo__link']} href='#' title='Pinhome Financial'>
              <PinfinanceLogo />
            </a>
          </Link>
        </div>
        <div className={styles.header__wrapper__right}>
          {navLinks}
          <div className={styles['header__extra-main-nav-item']}>
            {extraMainNavigationItems}
          </div>
          {extraNavigationItems}
          {
            user && (
              <>
                <div className={styles['account-section']}>
                  <Account
                    name={splitName(user)?.firstName ?? ''}
                    onLogOut={handleLogout}
                  />
                </div>
                <div data-testid='icon-profile' className={styles['account-section__mobile']} onClick={handleProfileToogle}>
                  <Avatar
                    name={splitName(user)?.firstName ?? ''}
                    size='large'
                    variant='circular'
                  />
                </div>
              </>
            )
          }
        </div>
      </div>
      {
        (isShowMenu || extraMainNavigationItems != null) && (
          <div
            data-testid="header-overlay-mobile"
            className={cx(styles.header__mobile__overlay, { [styles['header__mobile__overlay-active']]: isShowOverlayBurgerMenu === true })}>
            <div className={styles.header__mobile__navigation__item__wrapper}>
              <div className={styles.navigation__item}>
                <PinfinanceLogo />
                <IconButton fullWidth={false} icon={<Close24Icon />} onClick={handleMenuToogle} testId='btn-navbar-close-overlay' />
              </div>
              <Divider />
              {navLinks}
              <div ref={overlayRef}>
                {extraMainNavigationItemsOverlay ?? extraMainNavigationItems}
              </div>
              <div className={styles['header__mobile__overlay-extra-nav-items']}>
                {extraNavigationItemsOverlay ?? extraNavigationItems}
              </div>
            </div>
          </div>
        )
      }
      <div
        data-testid="header-overlay-profile-mobile"
        className={cx(styles.header__mobile__profile__overlay,
          { [styles['header__mobile__profile-overlay-active']]: isShowOverlayProfile === true })}>
        <div className={styles.header__mobile__navigation__item__wrapper}>
          <div className={styles.navigation__item}>
            <PinfinanceLogo />
            <IconButton fullWidth={false} icon={<Close24Icon />} onClick={handleProfileToogle} testId='btn-navbar-close-overlay' />
          </div>
          <Divider />
          <div className={styles.mobile__profile}>
            <div className={styles['mobile__profile-content']}>
              <Avatar
                name={splitName(user)?.firstName ?? ''}
                size='large'
                variant='circular'
              />
              <div>
                <Text typography='label1' component='p'>{splitName(user)?.firstName}</Text>
                <Text typography='body2' component='p'>{customerTypeName}</Text>
              </div>
            </div>
            <Divider />
          </div>
          {profileLinks}
          <button
            className={cx(styles.navigation__item, styles['mobile__profile-logout'])} onClick={handleLogout}
            data-testid='btn-logout-navbar-mobile'>
            <Text typography='body2'>
              Keluar
            </Text>
          </button>
        </div>
      </div>
    </header >
  );
};

export default NavigationBar;
