export const pinfinanceLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance-logo.png';

// PARTNERS
export const ojkLogoUrl = 'https://pinhome-web-assets.imgix.net/ojk-logo.png';
export const singaLogoUrl = 'https://pinhome-web-assets.imgix.net/agent-app/images/singa-logo.png';
export const singaVerticalLogoUrl = 'https://pinhome-web-assets.imgix.net/agent-app/images/singa-vertical-logo.png';
export const dompetKilatLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/partner/dompet-kilat.png';
export const danaSyariahLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/partner/dana-syariah.png';
export const bniLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/partner/bni-1.png';
export const permataLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/partner/permata.png';
export const bcaLogoUrl = 'https://pinhome-web-assets.imgix.net/pinfinance/partner/bca.png';

// DOWNLOAD APP
export const downloadGooglePlay = 'https://pinhome-web-assets.imgix.net/download-google-play.png';
export const downloadAppStore = 'https://pinhome-web-assets.imgix.net/download-app-store.png';

export const iconBridgingLoan = 'https://pinhome-web-assets.imgix.net/icon-bridging-loan.png';

//ILLUSTRATION
export const PromptIllustration = 'https://pinhome-web-assets.imgix.net/pinfinance/prompt-illustration.png';
