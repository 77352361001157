import type { RefObject } from 'react';
import { useRef, useEffect } from 'react';

const addClickListener = (ref: RefObject<HTMLElement>, onClick: VoidFunction) => {
  const handleWindowClick = (e: MouseEvent | TouchEvent) => {
    if (ref && onClick) {
      let node = e.target as HTMLElement | ParentNode | null;
      while (node) {
        if (ref.current === node) {
          onClick();
          return;
        }
        node = node.parentNode;
      }
    }
  };

  window.addEventListener('click', handleWindowClick);
  return (): void => {
    window.removeEventListener('click', handleWindowClick);
  };
};

const useClickHandler = (onClick: VoidFunction) => {
  const ref = useRef(null);
  useEffect(
    () => addClickListener(ref, onClick),
    [onClick, ref]);
  return ref;
};

export default useClickHandler;