import type { NavbarLinkType } from './types';

export const navigationLinks: NavbarLinkType[] = [
  {
    label: 'Beranda',
    href: '/',
    name: 'pinfinance-home'
  },
  {
    label: 'Komisi Instan',
    href: '/komisi-instan',
    name: 'komisi-instan'
  },
  {
    label: 'Bridging Loan',
    href: '/bridging-loan',
    name: 'bridging-loan'
  }
];


export const accountLinks: NavbarLinkType[] = [
  {
    href: 'dashboard',
    label: 'Dashboard',
    name: 'dashboard'
  },
  {
    href: 'edit-profile',
    label: 'Profil',
    name: 'profile'
  }
];
