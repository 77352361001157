import React from 'react';

import { Youtube32Icon } from '@pinvest/sirius-icons';
import Image from 'next/image';

import LinkedIn from './components/LinkedIn';
import { aboutSection, informationSection, PinfinanceWhiteLogo } from './config';
import styles from './styles.module.css';
import type { FooterNavSection } from './types';

const footerNavSections: FooterNavSection[] = [
  {
    links: aboutSection,
    name: 'about-section'
  },
  {
    links: informationSection,
    name: 'information-section'
  }
];

const Footer: React.FC = () => {

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__wrapper}>
        <section className={`${styles.footer__section} ${styles['footer__section-logo__and__social']}`}>
          <div className={styles.footer__logo}>
            <Image src={PinfinanceWhiteLogo} width={130} height={50} alt="pinfinance logo" />
          </div>
          <div className={styles.footer__social}>
            <a
              className={styles['footer__social-item']}
              href="https://www.linkedin.com/company/pinhomeid/"
              target="_blank"
              rel="noopener noreferrer"
              data-name="linkedin"
            >
              <LinkedIn />
            </a>
            <a
              className={styles['footer__social-item']}
              href="https://www.youtube.com/channel/UCoiSd_yJjKpiRFe_ICfktFA"
              target="_blank"
              rel="noopener noreferrer"
              data-name="youtube"
            >
              <Youtube32Icon />
            </a>
          </div>
          <div className={styles.footer__copyright}>
            {new Date().getFullYear()} © PT. Properti Solusi Manajemen. All Rights Reserved.
          </div>
        </section>
        {footerNavSections.map(({ links, name }) => (
          <section
            key={name}
            className={`${styles.footer__section} ${styles[name]}`}
          >
            <ul className={styles.footer__link}>
              {links.map(link => {
                const anchor = (
                  <a
                    href={link.href}
                    data-name={link.name}
                    className={styles['footer__link-anchor']}
                  >
                    {link.label}
                  </a>
                );

                return (
                  <li className={styles['footer__link-item']} key={`link-${link.name}`}>
                    {anchor}
                  </li>
                );
              })}
            </ul>
          </section>
        ))}
      </div>
    </footer>
  );
};

export default Footer;

