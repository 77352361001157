import type { UserInfo } from '@/types/user/userInfo';

export const splitName = (user: UserInfo | null) => {
  if (!user) {
    return null;
  }

  const name = user.name.split(' ');

  return {
    firstName: name[0],
    lastName: name[1]
  };
};
