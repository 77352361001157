export enum CustomerType {
  BUSINESS = 'customer_type.business',
  PERSONAL = 'customer_type.personal'
}

export const customerTypeMapName = {
  business: 'Akun Badan Usaha',
  personal: 'Akun Individual'
};

export enum LegalEntityType {
  PT = 'legal_entity.limited_company',
  CV = 'legal_entity.cooperative',
  OTHERS = 'legal_entity.others'
};

export const entityTypeOptions = [
  { label: 'PT', value: LegalEntityType.PT },
  { label: 'CV', value: LegalEntityType.CV },
  { label: 'Lainnya', value: LegalEntityType.OTHERS }
];
