import type { FooterLink } from './types';

export const aboutSection: FooterLink[] = [
  {
    label: 'Pinfinance',
    href: '/',
    name: 'pinfinance-home'
  },
  {
    label: 'Komisi Instan',
    href: '/komisi-instan',
    name: 'komisi-instan'
  },
  {
    label: 'Bridging Loan',
    href: '/bridging-loan',
    name: 'bridging-loan'
  }
];

export const informationSection: FooterLink[] = [
  {
    label: 'Ketentuan & Kebijakan Privasi',
    href: '/kebijakan-privasi',
    name: 'ketentuan-kebijakan-privasi'
  }
];

export const PinfinanceWhiteLogo = 'https://pinhome-staging-public.s3.amazonaws.com/pinfinance-white-logo.png';
